import React, { useState } from 'react'
import './App.css'
import coin from './images/coin.png'
import telegram from './images/telegram.png'

const App = () => {
  const [showBox, setShowBox] = useState(true)
  const handleButtonClick = () => {
    setShowBox(true)
  }

  return (
    <div className='App'>
      <h3>S<span>tron</span>gcoin</h3>
      <h4>Tap and earn. On Tron blockchain.</h4>
      <a href='https://t.me/StrongcoinBot' target='_blank' rel="noopener noreferrer">
        <div id="box" className={showBox ? "show" : ""}>Play for free in Telegram <img src={telegram} alt='telegram'/></div>
      </a>
      <img className='coin' src={coin} alt='coin'/>
    </div>
  )
}

export default App